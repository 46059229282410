<template>
  <div class="nopermission">
    <h1>You do not have permission to view this page.</h1>
  </div>
</template>

<script >
export default {
  name: 'NoPermission'
}
</script>

<style lang="scss" scoped>
.nopermission {
  text-align: center;
  padding: 40px 0;
}
</style>
